
  export default {
    props: {
      search: {
        type: String,
        required: true,
      },
      orderBy: {
        type: String,
        required: true,
      },
      orderDir: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchText: '',
        orderBySelect: 'billing_creation_date:desc',
        orderByItems: [
          {
            value: 'billing_nr:desc',
            text: this.$t('orderBy.billing_nr.desc'),
          },
          {
            value: 'billing_nr:asc',
            text: this.$t('orderBy.billing_nr.asc'),
          },
          {
            value: 'billing_title:desc',
            text: this.$t('orderBy.billing_title.desc'),
          },
          {
            value: 'billing_title:asc',
            text: this.$t('orderBy.billing_title.asc'),
          },
          {
            value: 'agency:desc',
            text: this.$t('orderBy.agency.desc'),
          },
          {
            value: 'agency:asc',
            text: this.$t('orderBy.agency.asc'),
          },
          {
            value: 'billing_editing_date:desc',
            text: this.$t('orderBy.billing_editing_date.desc'),
          },
          {
            value: 'billing_editing_date:asc',
            text: this.$t('orderBy.billing_editing_date.asc'),
          },
          {
            value: 'billing_creation_date:desc',
            text: this.$t('orderBy.billing_creation_date.desc'),
          },
          {
            value: 'billing_creation_date:asc',
            text: this.$t('orderBy.billing_creation_date.asc'),
          },
        ],
      };
    },
    created() {
      this.searchText = this.search;
      this.orderBySelect =
        this.orderBy + ':' + this.orderDir || this.orderBySelect;
    },
    methods: {
      changeSorting(value) {
        this.$emit('sort-invoices', value);
      },
    },
  };
